/* work sans */
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

/* space mono font */
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');


.modal-dialog-scrollable {
  height: calc(100% - var(1.75rem) * 2) !important;
}
body.modal-open
{
  overflow-y: hidden !important;
}
/* common css */
.workSans{
  font-family: "Work Sans", sans-serif !important;
}
.spaceMono{
  font-family: "Space Mono", monospace !important;
}
*,body{
  font-family: "Work Sans", sans-serif !important;
}
.fa, .fas {
  font-family: 'Font Awesome 5 Pro' !important;
  font-weight: 900;
}

.higher__top{
  margin-top: 100px;
}
.higher__bottom{
  margin-bottom: 100px;
}
.higher__tooTop{
  margin-top: 200px;
}
.higher__tooBottom{
  margin-bottom: 200px;
}
.pointer{
  cursor: pointer !important;
}
/* end of common css */

/* home css */
.tf-slider{
  background: url('./assets/images/kitty/banner.png');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.kitty__headerLogo{
  height: 65px !important;
  width: auto;
}
.kitty__homeTitles{
  color: #EC3DF3;
  font-size: 30px !important;
  letter-spacing: 1px;
}
.kitty__homesunImg{
  min-height: 400px;
}
.kitty__homeSunHint{
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  word-spacing: 2px;
}
.kitty__nftcardImg{
  border-radius: 7px !important;
}
.sc-product:hover .features .product-media img{
  transform: none !important;
}
.sc-product .bottom .details-product .author .avatar{
  height: 30px !important;
  width: 30px !important;
  border: 2px solid #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.kitty__cardName{
  font-size: 18px;
  font-weight: 700;
}
.sc-product .top{
  position: relative !important;
  left: unset !important;
  top: unset !important;
}
.sc-product .top .wish-list{
  border: none !important;
  background-color: transparent !important;
  height: unset !important;
  width: unset !important;
}
.sc-product .top .wish-list a::after{
  /* color: #DD2E44 !important; */
  font-size: 16px !important;
}
.sc-product .top .wish-list.active a::after, .sc-product .top .wish-list:hover a::after{
  color: #DD2E44 !important;
}
.sc-product .top{
  margin-bottom: 0px !important;
}
.kitty__cardOwner{
  font-size: 15px;
  font-weight: 600;
}
.kitty__cardPrice{
  color: #fff !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}
.sc-product .bottom .product-button.kitty_productBtn a{
  background-image: linear-gradient(to right,#6A11CB,#F758FF);
  border: none !important;
  padding: 10px 20px;
}
.sc-product .bottom .product-button.kitty_productBtn a:hover{
  color: #fff !important;
  transform: scale(0.95);
}
.kitty__nftCardHolder{
  background-image: linear-gradient(to bottom right,#00000000 70%,#8D8DFF),
                    linear-gradient(to top left,#00000000 70%,#8D8DFF);
  padding: 1px;
  border-radius: 10px;
}
.sc-product{
  margin-bottom: 0px !important;
}
.sc-product:hover{
  transform: none !important;
}
.kitty__nftCardHolder:hover{
  background-image: linear-gradient(to bottom right,#00000000,#8D8DFF 100%),
                    linear-gradient(to top left,#00000000,#8D8DFF 100%);
                    transition: 1s all linear;
}
.dropdown > a{
  border-radius: 5px !important;
  border-image-slice: 1 !important;
  border-width: 1px !important;
  border-image-source: linear-gradient(to left, #F758FF, #6A11CB) !important;
  border-image-slice: fill !important;
  color: #fff !important;
}
.dropdown ul,.dropdown li{
  background: #6A11CB !important;
}
.dropdown li:hover{
  background: #F758FF !important;
  color: #fff !important;
}
.dropdown li:hover span{
  color: #fff !important;
}
.show li:hover span::after{
  background: #fff !important;
}
.filter-menu li.active, .filter-menu li:hover{
  background-image: linear-gradient(to right,#6A11CB,#F758FF);
  border: 1px solid transparent;
}
.filter-menu li{
  border: 1px solid #8D8DFF;
  background-color: #171741;
  color: #fff;
}
.kitty__topCreatorName{
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
.kitty__topCreatorGrid{
  display: grid;
  grid-template-columns: repeat(auto-fit,(minmax(18%,1fr)));
}
.kitty__roadmapImg{
  max-width: 85%;
  position: relative !important;
}
.kitty__roadMapHolder{
  position: relative !important;
}
.kitty__teamCard{
  border-radius: 8px;
  margin-bottom: 20px;
}
.kitty__teamsGrid{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 30px;
  margin-top: 50px;
}
.kitty__teamCardTop{
  background-image: url('./assets/images/kitty/teambg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  min-height: 150px;
  width: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  position: relative;
}
.kitty__teamCardMem{
  height: 150px;
  width: 150px;
  min-height: 150px;
  min-width: 150px;
  max-height: 150px;
  max-width: 150px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #fff;
  position: absolute;
  top: -15%;
}
.kitty__teamCardMemName{
  color: #fff;
  opacity: .8;
  font-size: 16px;
  font-weight: 700;
}
.kitty__teamCardMemPos{
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  margin-top: 8px !important;
}
.kitty__teamCardBot{
  background-color: #171741;
}
.kitty__gradBtn{
  background-image: linear-gradient(to right,#6A11CB,#F758FF);
  padding: 7px 30px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
  border-radius: 30px;
  border: none;
}
.kitty__gradBtn:hover{
  background-image: linear-gradient(to right ,#F758FF,#6A11CB) !important;
  color: #fff;
}
.kitty__tokenomicsTitle{
  color: #EC3DF3;
  font-size: 35px !important;
  font-weight: 700;
}
.kitty__tokenomicsSubHint{
  color: #fff;
  font-size: 18px !important;
  font-weight: 500;
  margin-top: 30px !important;
}
.kitty__tokenomicsHint{
  color: #fff;
  font-size: 15px !important;
  font-weight: 500;
  margin-top: 30px !important;
}
.kity__tokenomicsDesc{
  color: #fff;
  font-size: 25px !important;
  font-weight: 600;
  margin-top: 40px !important;
}
.kitty__marqueeImages{
  height: 100px;
  width: 200px;
  margin-right: 70px;
}
.kitty__footerLogo{
  height: 100px;
  width: 85px;
}
.btn-loadmore .tf-button.style-8.kitty__loadMoreBtn, .btn-loadmore .tf-button.style-2.kitty__loadMoreBtn,.btn-loadmore .tf-button.kitty__loadMoreBtn
{
  border-radius: 30px !important;
  background-image: linear-gradient(to right,#6A11CB,#F758FF) !important;
  padding: 12px 40px !important;
  border: none !important;
  outline: none !important;
}
.btn-loadmore .tf-button.style-8.kitty__loadMoreBtn:hover, .btn-loadmore .tf-button.style-2.kitty__loadMoreBtn:hover,.btn-loadmore .tf-button.kitty__loadMoreBtn:hover{
  background-image: linear-gradient(to right ,#F758FF,#6A11CB) !important;
}
.kitty__homeYellowRound{
  position: absolute;
  top: 5%;
  left: 40%;
  height: 250px;
  width: 250px;
}
.kitty__homeVioletRound{
  position: absolute;
  top: 40%;
  right: -5%;
  height: 180px;
  width: 180px;
}
.kitty__homeUFO{
  position: absolute;
  bottom: 5%;
  left: 20%;
  height: 150px;
  width: 150px;
}
.kitty__homeCardLottie{
  position: absolute;
  bottom: -13%;
  left: -37%;
  height: 400px;
  width: 400px;
}
.kitty__venusRound{
  position: absolute;
  top: 60%;
  left: 6%;
  height: 80px;
  width: 80px;
}
.kitty__homeBanner.tf-slider{
  min-height: 100vh;
}
.kitty__bannerMerger{
  position: absolute;
  bottom: -7%;
  height: 150px;
  min-width: 100%;
  left: 0;
  right: 0;
}
.kitty__aboutSec{
  position: relative;
}
.kitty__aboutCloud{
  position: absolute;
  left: 0;
  top: -50%;
  max-width: 550px;
  max-height: 780px;
}
.kitty__bannerRight{
  position: relative;
}
.kitty__hotActionHolder{
  position: relative !important;
}
.kitty__hotYellowRound{
  position: absolute;
  top: -25%;
  right: 0%;
  height: 250px;
  width: 250px;
}
.kitty__hotCloud{
  position: absolute;
  right: 0;
  bottom: 0;
  height: 80%;
  width: 35%;
}
.kitty__createrCloud{
  position: absolute;
  bottom: -80%;
  left: 0;
}
.kitty__starLottie{
  position: absolute;
  right: 5%;
  bottom: 10%;
  height: 100px;
  width: 100px;
}

.kitty__gradientLottie{
  position: absolute;
  left: 55%;
  top: 0%;
  height: 100px;
  width: 150px;
}
.kitty__teamers{
  position: relative !important;
}
.kitty__teamersCloud{
  position: absolute;
  left: 0;
  top: -35%;
    height: 202%;
}
.kitty__partnerBackers,.kitty__footer{
  position: relative !important;
}
.kitty__backersCloud{
  position: absolute;
  right: 0%;
  top: -10%;
  height: 1000px;
  width: 600px;
}
.kitty__footerLeftCloud{
  position: absolute;
  left: 0%;
  top: -100%;
}
.kitty__footerRighttCloud{
  position: absolute;
  right: 0%;
  bottom: 0%;
}
.kitty__hotUFO{
  position: absolute;
  right: 2%;
  top: 50%;
  height: 150px;
  width: 150px;
}
.kitty__hotleftUFO{
  position: absolute;
  left: 1%;
  top: 80%;
  height: 120px;
  width: 120px;
}
.card-img-top{
  border-radius: none !important;
}
/* end of home css */


/* footer css */
.kitty__footerHint{
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}
.kitty__socialFlexer{
  margin-top: 10px;
}
.kitty__socialFlexer svg{
  height: 25px;
  width: 25px;
  fill: #F356FE;
}
.kitty__socialFlexer svg:hover{
  fill: #fff;
}
.kitty__socialFlexer{
  gap: 15px;
}
.footer .widget.widget-subcribe #subscribe-form .tf-button.kitty__footerSubBtn{
  background-image: linear-gradient(to right,#6A11CB,#F758FF);
  padding: 7px 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 30px !important;
}
.footer .widget.widget-subcribe #subscribe-form .tf-button.kitty__footerSubBtn:hover{
  background-image: linear-gradient(to right,#F758FF,#6A11CB);
  color: #fff !important;  
}
.footer .widget.widget-subcribe #subscribe-form input{
  border-radius: 30px !important;
}
/* end of footer css */

/* header css */
.header__scrolled.header{
  background-color: #171741 !important;
}
header #site-header-inner .header-right .tf-button.kitty__headerGradBtn{
  background-image: linear-gradient(to bottom right,#6A11CB,#F758FF);
  color: #fff !important;  
  border-radius: 30px !important;
  padding: 10px 20px !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  border: none !important;
}
.header-right .co-wa.kitty__headerGradBtn:hover{
  background-image: linear-gradient(to bottom left,#6A11CB,#F758FF) !important;
  color: #fff !important; 
}
#main-nav > ul > li .sub-menu li a::after{
  background: #fff !important;
}
header{
  border-bottom: none !important;
}
/* end of header css */

/* explore css */
.kitty__exploreHint{
  color: #fff;
  font-size: 15px;
}
.kitty__exploreCloud{
  position: absolute;
  top: 0;
  left: 0;
  max-width: 550px;
  max-height: 900px;
}
.kitty__explore,.kitty__blog,.tf-explore-more,.kitty__privacy,.kitty__board{
  position: relative;
  z-index: 1;
}
/* end of explore css */


/* info css */
.tf-tab .menu-tab li:hover a, .tf-tab .menu-tab li.active a{
  color: #EC3DF3 !important;
}
.tf-tab .menu-tab li.active::after{
  background-color: #EC3DF3 !important;
}
.tf-tab .tabsections{
  border-color: #8686F3 !important;
}
.kitty__infoTitle{
  font-size: 25px !important;
  font-weight: 600 !important;
}
.kitty__infoCreator{
  gap: 10px;
}
.kitty__infoLabel{
  color: #fff !important;
}
.kitty__infotabLabel{
  font-size: 15px !important;
  font-weight: 600 !important;
}
.try .tf-button.kitty__loadMoreBtn{
  background-image: linear-gradient(to bottom right,#6A11CB,#F758FF);
  color: #fff !important;  
  border-radius: 30px !important;
  padding: 10px 20px !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  border: none !important;
}
.try .tf-button.kitty__loadMoreBtn:hover{
  background-image: linear-gradient(to bottom right,#F758FF,#6A11CB);
}
.tf-button.kitty__loadMoreBtn{
  background-image: linear-gradient(to bottom right,#6A11CB,#F758FF);
  color: #fff !important;  
  border-radius: 30px !important;
  padding: 10px 20px !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  border: none !important;
}
.tf-button.kitty__loadMoreBtn:hover{
  background-image: linear-gradient(to bottom right,#F758FF,#6A11CB);
}
.kitty__secondaryBtn{
  border-radius: 30px !important;
  /* border-image-slice: 1 !important; */
  border: 1px solid #8D8DFF !important;
  /* border-image-source: linear-gradient(to left, #F758FF, #6A11CB) !important; */
  /* border-image-slice: fill !important; */
  color: #fff !important;
  background-color: transparent !important;
  outline: none !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  padding: 10px 30px !important;
}
.kitty__secondaryBtn:hover{
  background-image: linear-gradient(to bottom right,#F758FF,#6A11CB);
  border-color:transparent !important;
}
.kitty__info{
  padding-top: 0px !important;
}
/* end of info css */

/* coming soon css */
.comingsoon__headerlogo{
  position: absolute;
  top: 5%;
  left: 5%;
}
/* end of coming soon css */

/* activity css */
.dashboard-user .dashboard-infor{
  margin-top: -40% !important;
}
.avatar .edit_profile{
  right: -70%;
  top: 60%;
  height: 25px;
  width: 25px;
}
.dashboard-user .dashboard-infor .pax{
  margin-top: 0px !important;
  color: #EC3DF3 !important;
}
.dashboard-user .dashboard-filter .filter-menuu li.active a, .dashboard-user .dashboard-filter .filter-menuu li:hover a{
  color: #EC3DF3 !important;
}
/* end of activity css */

/* create css */
.upper__indexing,.kitty__creatPage{
  position: relative;
  z-index: 1;
}
.kitty__loadMorePad,.kitty__loadMoreBtn.kitty__loadMorePad{
  padding: 10px 30px !important;
}
.kitty__createInp{
  border-color: #8D8DFF !important;
}
/* end of create css */

/* modal css */
.kitty__putonsaleBtn{
  flex-grow: 1;
}
.kitty__putonsaleBtnHolder{
  gap: 20px;
}
/* end of modal css */

/* media queries */
@media(1200px <= width < 1400px){
  .kitty__homeCardLottie{
    position: absolute;
    bottom: -15%;
    left: -20%;
    height: 300px;
    width: 300px;
  }
  .kitty__bannerRightImg{
    max-width: 90% !important;
  }
}
@media(992px <= width < 1200px){
  .kitty__homeCardLottie{
    position: absolute;
    bottom: -20%;
    left: -10%;
    height: 270px;
    width: 270px;
  }
  .kitty__bannerRightImg{
    max-width: 80% !important;
  }
  .kitty__homeYellowRound {
    position: absolute;
    top: 0%;
    left: 40%;
    height: 150px;
    width: 150px;
}
.kitty__homeVioletRound {
  position: absolute;
  top: 40%;
  right: -5%;
  height: 130px;
  width: 130px;
}
.kitty__homesunImg{
  min-height: 300px;
  max-height: 300px;
}
.kitty__venusRound{
  height: 60px;
  width: 60px;
}
}
@media (width <= 990px){
  .kitty__headerSearchWeb{
    display: none;
  }
}
@media(768px <= width < 992px){
  .kitty__homeCardLottie{
    position: absolute;
    bottom: -15%;
    left: -25%;
    height: 250px;
    width: 250px;
  }
  .kitty__bannerRightImg{
    max-width: 90% !important;
  }
  .kitty__homeYellowRound {
    position: absolute;
    top: -5%;
    left: 40%;
    height: 150px;
    width: 150px;
}
.kitty__homeVioletRound {
  position: absolute;
  top: 40%;
  right: -10%;
  height: 130px;
  width: 130px;
}
}
@media(width < 768px){
  .kitty__bannerLeft{
    margin-bottom: 80px;
  }
  
}
@media(576px <= width < 768px){
  .kitty__bannerRightImg{
    max-width: 80% !important;
  }
  .kitty__homeCardLottie{
    position: absolute;
    bottom: -12%;
    left: -3%;
    height: 250px;
    width: 250px;
  }
  .kitty__homeYellowRound {
    position: absolute;
    top: 0%;
    left: 30%;
    height: 150px;
    width: 150px;
}
.kitty__homeVioletRound {
  position: absolute;
  top: 40%;
  right: -10%;
  height: 130px;
  width: 130px;
}
.kitty__homesunImg{
  min-height: 300px;
  max-height: 300px;
}
.kitty__venusRound{
  height: 60px;
  width: 60px;
}
.homepage .heading{
  font-size: 25px !important;
}
.tf-heading .sub-heading{
  font-size: 15px !important;
}
}
@media (width < 576px){

.kitty__gradientLottie{
  position: absolute;
  left: 55%;
  top: 0%;
  height: 80px;
  width: 80px;
}
.kitty__homeUFO {
  position: absolute;
  bottom: 5%;
  left: 40%;
  height: 80px;
  width: 80px;
}

.kitty__starLottie {
  position: absolute;
  right: 5%;
  bottom: 0%;
  height: 60px;
  width: 60px;
}
}
@media(450px <= width < 576px){
  .kitty__bannerRightImg{
    max-width: 80% !important;
  }

  .kitty__homeCardLottie{
    position: absolute;
    bottom: -18%;
    left: 0%;
    height: 230px;
    width: 230px;

  }
  .kitty__homeYellowRound {
    position: absolute;
    top: 0%;
    left: 30%;
    height: 150px;
    width: 150px;
}
.kitty__homeVioletRound {
  position: absolute;
  top: 40%;
  right: -15%;
  height: 130px;
  width: 130px;
}
.kitty__homesunImg{
  min-height: 250px;
  max-height: 250px;
}
.kitty__venusRound{
  height: 50px;
  width: 50px;
}
.homepage .heading{
  font-size: 25px !important;
}
.tf-heading .sub-heading{
  font-size: 15px !important;
}
}
@media(width < 450px){
  .kitty__bannerRightImg{
    max-width: 80% !important;
  }
  .kitty__homeCardLottie{
    position: absolute;
    bottom: -12%;
    left: -10%;
    height: 200px;
    width: 200px;
  }
  .kitty__homeYellowRound {
    position: absolute;
    top: 1%;
    left: 30%;
    height: 150px;
    width: 150px;
}
.kitty__homeVioletRound {
  position: absolute;
  top: 40%;
  right: -20%;
  height: 130px;
  width: 130px;
}
.kitty__homesunImg{
  min-height: 200px;
  max-height: 200px;
}
.kitty__venusRound{
  height: 40px;
  width: 40px;
}
.homepage .heading{
  font-size: 20px !important;
}
.tf-heading .sub-heading{
  font-size: 13px !important;
}

}
@media (width <= 400px){
  .kitty__homeCardLottie{
    position: absolute;
    bottom: -18%;
    left: -10%;
    height: 200px;
    width: 200px;
  }
}
/* end of media queries */


/* start of hariharan css */

.kitty_roadmp_wraper{
  max-width: 85%;
}.kitty_roadmp_wraper img{
  max-width: 100%;
}



.roadmp_title{
  color:white;
  font-weight: 700;
  font-size: 17px;
}
.roadmp_content{
  color:white;
  line-height: 1.7;
}


@media(min-width:768px){
  .roadmp_max_wd{
    max-width: 120px;
  }
  .roadmp_title{
    font-size:8px;
  }
  .roadmp_content{
    font-size:8px;
  }
  .roadmp_config{
    position: absolute;
    left: 19%;
    top: 100%;
  }
  .roadmp_assem{
    position: absolute;
    left: 36%;
    top: 84.5%;
  }
  .roadmp_takeoff{
    position: absolute;
    left: 56%;
    top:71%;
    /* background: red; */
  }
  .roadmp_travel_kitty{
    position: absolute;
    left: 70%;
    top:57%;
    /* background: red; */
  }
}

@media(min-width:850px){
  .roadmp_max_wd{
    max-width: 135px;
  }
  .roadmp_title{
    font-size:10px;
  }
  .roadmp_content{
    font-size:9px;
  }


}
@media(min-width:992px){
  .roadmp_max_wd{
    max-width: 140px;
  }
  .roadmp_title{
    font-size:11px;
  }
  .roadmp_content{
    font-size:10px;
  }
 
  .roadmp_config{
    position: absolute;
    left: 19%;
    top: 101%;
  }
  .roadmp_takeoff{
    position: absolute;
    left: 56%;
    top:70%;
  }
  .roadmp_travel_kitty{
    position: absolute;
    left: 70%;
    top:55%;
    /* background: red; */
  }
}
@media(min-width:1100px){
  .roadmp_max_wd{
    max-width: 155px;
  }
  .roadmp_title{
    font-size:12px;
  }
  .roadmp_content{
    font-size:11px;
  }
  .roadmp_takeoff{
    position: absolute;
    left: 56%;
    top:69.5%;
  }

}
@media(min-width:1200px){
  .roadmp_max_wd{
    max-width: 220px;
  }
  .roadmp_title{
    font-size:15px;
  }
  .roadmp_content{
    font-size:13px;
  } 
  .roadmp_travel_kitty{
    position: absolute;
    left: 70%;
    top:54%;
    /* background: red; */
  }

}
@media(min-width:1320px){
  .roadmp_max_wd{
    max-width: 220px;
  }
  .roadmp_title{
    font-size:15px;
  }
  .roadmp_content{
    font-size:13px;
  }

}
@media(min-width:1500px){
  .roadmp_max_wd{
    max-width: 220px;
  }
  .roadmp_title{
    font-size:15px;
  }
  .roadmp_content{
    font-size:13px;
  }
  .roadmp_travel_kitty{
    position: absolute;
    left: 70%;
    top:52%;
    /* background: red; */
  }

}
@media(min-width:1600px){
  .roadmp_max_wd{
    max-width: 250px;
  }
  .roadmp_title{
    font-size:18px;
  }
  .roadmp_content{
    margin-top: 10px;
    font-size:14px;
  }
  .roadmp_takeoff{
    position: absolute;
    left: 56%;
    top:68.5%;
  }

 
}
@media(min-width:1800px){
  .roadmp_max_wd{
    max-width: 280px;
  }
  .roadmp_title{
    font-size:20px;
  }
  .roadmp_content{
    font-size:16px;
    margin-top: 14px;
  }

  .roadmp_assem{
    position: absolute;
    left: 36%;
    top: 82.5%;
  }
  .roadmp_takeoff {
    position: absolute;
    left: 56%;
    top: 67%;
}
  .roadmp_travel_kitty{
    position: absolute;
    left: 70%;
    top:51%;
    /* background: red; */
  }
 
}
@media(min-width:1920px){
  .roadmp_max_wd{
    max-width: 320px;
  }
  .roadmp_title{
    font-size:21px;
  }
  .roadmp_content{
    font-size:17px;
  }
}
/* end of hariharan css */

/* end of kitty css */



